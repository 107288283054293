<template>
    <div>
        <b-overlay :show="show" rounded="sm">
            <b-card no-body>
                <div class="m-2">
                    <b-row>
                        <b-col cols="12" md="3">
                            <label for="class">Assignee</label>
                            <v-select class="w-100" 
                                v-model="record_filter.assigneeId" 
                                placeholder="Select a assignee" 
                                :clearable="true"
                                :reduce="(get_assignees) => get_assignees._id" 
                                :options="get_assignees" 
                                label="name"
                                @input="selectAssignee()">
                                <template v-slot:option="option">
                                    {{ option.name }}
                                </template>
                            </v-select>
                        </b-col>
                        <b-col cols="12" md="3">
                            <label for="class">Grades</label>
                            <v-select class="w-100"
                                v-model="record_filter.gradeId"
                                placeholder="Select a grade"
                                :clearable="true"
                                :reduce="(gradeList) => gradeList._id"
                                :options="gradeList"
                                label="className"
                                @input="selectGrade()">
                                <template v-slot:option="option">
                                    {{ option.className }}
                                </template>
                            </v-select>
                        </b-col>
                        <b-col cols="12" md="3">
                            <label for="class">Subjects</label>
                            <v-select class="w-100"
                                v-model="record_filter.subjectId"
                                placeholder="Select a subject"
                                :clearable="true"
                                :reduce="(get_subjects) => get_subjects._id"
                                :options="get_subjects"
                                label="subjectName"
                                @input="selectSubject()">
                                <template v-slot:option="option">
                                    {{ option.subjectName }}
                                    <span v-if="option.class">
                                        ({{ option.class.className }})
                                    </span>
                                </template>
                            </v-select>
                        </b-col>
                        <b-col cols="12" md="3">
                            <label for="class">Topics</label>
                            <v-select class="w-100"
                                v-model="record_filter.topicId"
                                placeholder="Select a topic"
                                :clearable="true"
                                :reduce="(get_topics) => get_topics._id"
                                :options="get_topics"
                                label="categoryName"
                                @input="selectTopic()">
                                <template v-slot:option="option">
                                    {{ option.categoryName }}
                                    <span v-if="option.class">
                                        ({{ option.class.className }})
                                    </span>
                                    <span v-if="option.subject">
                                        ({{ option.subject.subjectName }})
                                    </span>
                                </template>
                            </v-select>
                        </b-col>
                        <!-- <b-col cols="12" md="3">
                            <label for="class">Report</label>
                            <v-select class="w-100" 
                                v-model="record_filter.reportTime" 
                                placeholder="Select a assignee" 
                                :clearable="true"
                                :reduce="(get_report_time) => get_report_time.name" 
                                :options="get_report_time" 
                                label="label"
                                @input="selectReportTime()">
                                <template v-slot:option="option">
                                    {{ option.label }}
                                </template>
                            </v-select>
                        </b-col> -->
                    </b-row>
                </div>
                <!-- <div class="mx-2 mt-1 mb-1">
                    <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                        <div class="dataTables_info mt-1"></div>
                    </div>
                </div> -->
            </b-card>
            <div>
                <b-row class="flex-nowrap overflow-auto minimize-status-col-gap" v-if="record_filter.assigneeId || record_filter.gradeId || record_filter.subjectId || record_filter.topicId">
                    <b-col v-for="(status, index) in statuses" :key="index" md="3" class="col-item">
                        <div class="card status-container">
                            <div class="card-header">
                                {{ status.name.toUpperCase() }}
                                <span v-if="status.default_status && status.topics">{{ status.topics.length +  topicstodo.length}}</span>
                                <span v-else-if="status.topics">{{ status.topics.length }}</span>
                            </div>
                            <div class="card-body status-content">
                                <template>
                                    <div 
                                        v-for="topic in status.topics"
                                        :key="topic.name"
                                        class="status-card"
                                        >
                                        <span class="empty-overlay" @click="selectTopicFunc(status, topic)"></span>
                                        <div class="status-card-body">
                                            <div class="topic-media">
                                                <div class="img-container"
                                                    v-lazy-container="{
                                                        selector: 'img',
                                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                                        loading: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                                    }"
                                                    >
                                                    <b-img
                                                        :src="topic.categoryImg" 
                                                        :data-src="topic.categoryImg" 
                                                        :alt="topic.categoryName"
                                                    ></b-img>
                                                </div>
                                                <div
                                                    class="topic-media-overlay">
                                                    <span class="overlay" @click="selectTopicFunc(status, topic)"></span>
                                                    <div class="top-detail d-flex justify-content-between w-100">
                                                        <div class="preview text-white">
                                                            <!-- v-if="Helpers.isTopicAccess(getTopicsAccess, topic._id) || TokenService.getPermissions('view_topics')" -->
                                                            <b-link :to="{
                                                                    path: '/categorylevels/' + topic._id,
                                                                }" class="badge badge-primary">
                                                                Preview
                                                            </b-link>
                                                        </div>
                                                        <div class="attachments-count text-white" v-if="(topic.editors_requests && topic.editors_requests.length > 0) && TokenService.getPermissions('view_topics')">
                                                            <feather-icon icon="UsersIcon" size="16" />
                                                            {{ topic.editors_requests.length }}
                                                        </div>
                                                        <div class="attachments-count text-white" v-if="topic.attachments && topic.attachments.length > 0">
                                                            <feather-icon icon="ImageIcon" size="16" />
                                                        </div>
                                                    </div>
                                                    <div class="title-detail d-flex justify-content-between align-items-end">
                                                        <div class="topic-title" @click="selectTopicFunc(status, topic)">
                                                            <div v-if="topic.subject">
                                                                <span class="badge badge-primary">
                                                                    {{ topic.subject.subjectName }}
                                                                </span>
                                                            </div>
                                                            {{ topic.categoryName }}
                                                        </div>
                                                        <b-link 
                                                            :to="{
                                                                path: `/feedback-questions?subjectId=${topic.subjectId}&topicId=${topic._id}`,
                                                            }" 
                                                            class="feedbacks-count text-white">
                                                            <!-- <feather-icon icon="ImageIcon" size="16" /> -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width:16px;" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" /></svg>
                                                            <span>
                                                                {{
                                                                    topic.feedbacks && topic.feedbacks.length?topic.feedbacks.length:0
                                                                }}
                                                            </span>
                                                        </b-link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="authors-detail" v-if="topic.assigneesDetails || topic.editorsDetails">
                                                <div v-if="topic.assigneesDetails && topic.assigneesDetails.length > 0"
                                                    :class="[topic.editorsDetails && topic.editorsDetails.length > 0?'w-50':'w-100']"
                                                    >
                                                    <div style="margin-bottom: 5px;">Assignee</div>
                                                    <div class="assignee-detail avatarsList">
                                                        <div v-for="(a, aIndex) in topic.assigneesDetails" :key="aIndex" class="avatar-item">
                                                            <div class="image">
                                                                <b-avatar size="25" :src="a.avatar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="topic.editorsDetails && topic.editorsDetails.length > 0"
                                                    :class="[topic.assigneesDetails && topic.assigneesDetails.length > 0?'w-50':'w-100']"
                                                    >
                                                    <div style="margin-bottom: 5px;">Editor</div>
                                                    <div class="assignee-detail avatarsList">
                                                        <div v-for="(a, aIndex) in topic.editorsDetails" :key="aIndex" class="avatar-item">
                                                            <div class="image">
                                                                <b-avatar size="25" :src="a.avatar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="authors-detail" v-if="topic.assignee || topic.auditor">
                                                <div class="assignee-detail" v-if="topic.assignee"
                                                    :class="[topic.auditor?'w-50':'w-100']"
                                                    >
                                                    <div class="image">
                                                        <b-avatar size="25" :src="topic.assignee.avatar" />
                                                    </div>
                                                    <div class="name">
                                                        <div class="txt">
                                                            {{ topic.assignee.name }}
                                                        </div>
                                                        <span class="badge badge-secondary">Assignee</span>
                                                    </div>
                                                </div>
                                                <div class="assignee-detail" v-if="topic.auditor"
                                                    :class="[topic.assignee?'w-50':'w-100']"
                                                    >
                                                    <div class="image">
                                                        <b-avatar size="25" :src="topic.auditor.avatar" />
                                                    </div>
                                                    <div class="name">
                                                        <div class="txt">
                                                            {{ topic.auditor.name }}
                                                        </div>
                                                        <span class="badge badge-secondary">Auditor</span>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </template>
                                <template v-if="status.default_status">
                                    <div v-for="topic in topicstodo" :key="topic.name" class="status-card">
                                        <span class="empty-overlay" @click="selectTopicFunc(status, topic)"></span>
                                        <div class="status-card-body">
                                            <div class="topic-media">
                                                <div class="img-container"
                                                    v-lazy-container="{
                                                        selector: 'img',
                                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                                        loading: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                                    }"
                                                    >
                                                    <b-img
                                                        :src="topic.categoryImg" 
                                                        :data-src="topic.categoryImg" 
                                                        :alt="topic.categoryName"
                                                    ></b-img>
                                                </div>
                                                <div
                                                    class="topic-media-overlay">
                                                    <span class="overlay" @click="selectTopicFunc(status, topic)"></span>
                                                    <div class="top-detail d-flex justify-content-between w-100">
                                                        <div class="topic-title" @click="selectTopicFunc(status, topic)">
                                                            {{ topic.categoryName }}
                                                        </div>
                                                        <div class="top-detail d-flex justify-content-between align-items-start" @click="selectTopicFunc(status, topic)">
                                                            <div class="preview text-white">
                                                                <!-- v-if="Helpers.isTopicAccess(getTopicsAccess, topic._id) || TokenService.getPermissions('view_topics')" -->
                                                                <b-link :to="{
                                                                        path: '/categorylevels/' + topic._id,
                                                                    }" class="badge badge-primary">
                                                                    Preview
                                                                </b-link>
                                                            </div>
                                                            <div class="attachments-count text-white" v-if="(topic.editors_requests && topic.editors_requests.length > 0) && TokenService.getPermissions('view_topics')">
                                                                <feather-icon icon="UsersIcon" size="16" />
                                                                {{ topic.editors_requests.length }}
                                                            </div>
                                                            <div class="attachments-count text-white" v-if="topic.attachments && topic.attachments.length > 0">
                                                                <feather-icon icon="ImageIcon" size="16" />
                                                            </div>
                                                        </div>
                                                        <b-link 
                                                            :to="{
                                                                path: `/feedback-questions?subjectId=${topic.subjectId}&topicId=${topic._id}`,
                                                            }" 
                                                            class="feedbacks-count text-white" 
                                                            v-if="topic.feedbacks.length > 0">
                                                            <!-- <feather-icon icon="ImageIcon" size="16" /> -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width:16px;" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" /></svg>
                                                            <span>{{ topic.feedbacks.length }}</span>
                                                        </b-link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="authors-detail" v-if="topic.assigneesDetails || topic.editorsDetails">
                                                <div v-if="topic.assigneesDetails && topic.assigneesDetails.length > 0"
                                                    :class="[topic.editorsDetails && topic.editorsDetails.length > 0?'w-50':'w-100']"
                                                    >
                                                    <div style="margin-bottom: 5px;">Assignee</div>
                                                    <div class="assignee-detail avatarsList">
                                                        <div v-for="(a, aIndex) in topic.assigneesDetails" :key="aIndex" class="avatar-item">
                                                            <div class="image">
                                                                <b-avatar size="25" :src="a.avatar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="topic.editorsDetails && topic.editorsDetails.length > 0"
                                                    :class="[topic.assigneesDetails && topic.assigneesDetails.length > 0?'w-50':'w-100']"
                                                    >
                                                    <div style="margin-bottom: 5px;">Editor</div>
                                                    <div class="assignee-detail avatarsList">
                                                        <div v-for="(a, aIndex) in topic.editorsDetails" :key="aIndex" class="avatar-item">
                                                            <div class="image">
                                                                <b-avatar size="25" :src="a.avatar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="authors-detail" v-if="topic.assignee || topic.auditor">
                                                <div class="assignee-detail" v-if="topic.assignee"
                                                    :class="[topic.auditor?'w-50':'w-100']"
                                                    >
                                                    <div class="image">
                                                        <b-avatar size="25" :src="topic.assignee.avatar" />
                                                    </div>
                                                    <div class="name">
                                                        <div class="txt">
                                                            {{ topic.assignee.name }}
                                                        </div>
                                                        <span class="badge badge-secondary">Assignee</span>
                                                    </div>
                                                </div>
                                                <div class="assignee-detail" v-if="topic.auditor"
                                                    :class="[topic.assignee?'w-50':'w-100']"
                                                    >
                                                    <div class="image">
                                                        <b-avatar size="25" :src="topic.auditor.avatar" />
                                                    </div>
                                                    <div class="name">
                                                        <div class="txt">
                                                            {{ topic.auditor.name }}
                                                        </div>
                                                        <span class="badge badge-secondary">Auditor</span>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- <b-card no-body class="card-wrapper">
                        </b-card> -->
                    </b-col>
                </b-row>
            </div>
            <ShowTopicBoardModel
                :props_showTopicBoardModal="showTopicBoardModal"
                @modelClose="modelClose"
                @updatedParent="updatedParent"
                :props_selectedTopic="selectedTopicId"
                :props_topicStatus="topicStatus"
            />
        </b-overlay>

    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBreadcrumb,
    BCardText,
    BAvatar,
    BOverlay,
    BLink,
    BImg,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import TokenService from '../../TokenService';
import * as Helpers from '../../Helpers';
export default {
    components: {
        ShowTopicBoardModel: () => import("@/views/components/topic/ShowTopicBoardModel.vue"),
        vSelect,
        ToastificationContent,
        BBreadcrumb,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BCardText,
        BAvatar,
        BOverlay,
        BLink,
        BImg,
    },
    data() {
        return {
            isAddNotifications: false,
            searchQuery: "",
            show: false,
            alertMessageToast: "",
            bearerToken: "",
            gradeList: [],
            get_subjects: [],
            get_topics: [],
            isViewNotifications: false,
            statuses: [],
            topics: [],
            topicstodo: [],
            showTopicBoardModal: false,
            selectedTopicId: {},
            topicStatus: {},
            get_assignees: [],
            get_report_time: [],
            record_filter: {
                assigneeId: '',
                gradeId: '',
                subjectId: '',
                topicId: '',
                reportTime: '',
            },
            get_report: [],
            Helpers,
            getTopicsAccess: [],
            TokenService,
        };
    },
    created() {
        if(!TokenService.getPermissions("view_topics_review_board")) {
            this.$router.push("/error-404");
        }
    },
    mounted() {
        if(TokenService.getTopicReviewBoard('assigneeId')){
            this.record_filter.assigneeId = TokenService.getTopicReviewBoard('assigneeId');
        }
        if(TokenService.getTopicReviewBoard('gradeId')){
            this.record_filter.gradeId = TokenService.getTopicReviewBoard('gradeId');
        }
        if(TokenService.getTopicReviewBoard('subjectId')){
            this.record_filter.subjectId = TokenService.getTopicReviewBoard('subjectId');
        }
        if(TokenService.getTopicReviewBoard('topicId')){
            this.record_filter.topicId = TokenService.getTopicReviewBoard('topicId');
        }
        this.isViewNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "view_topics_lifecycle");
        this.bearerToken = "Bearer " + localStorage.getItem("_t")
        this.fetchGradeList();
        this.fetchTopicReviewStatuses();
    },
    methods: {
        selectTopicFunc(status, topic) {
            this.topicStatus = status;
            topic.v1 = Math.random();
            this.selectedTopicId = topic;
            this.showTopicBoardModal = true;
        },
        modelClose() {
            this.showTopicBoardModal = false;
            // this.fetchTopicReviewStatuses();
        },
        updatedParent() {
            this.fetchTopicReviewStatuses();
        },
        async fetchGradeList() {
            // this.show = true;
            try {
                const res = await axios.get(process.env.VUE_APP_API_URL + '/grade/all', {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                console.log('res=',res.data)
                this.gradeList = res.data;
                // this.show = false;
            } catch (error) {

            }
        },
        selectAssignee(){
            TokenService.saveTopicReviewBoard("assigneeId", this.record_filter.assigneeId);
            this.fetchTopicReviewStatuses();
        },
        selectGrade(){
            TokenService.saveTopicReviewBoard("gradeId", this.record_filter.gradeId);
            this.record_filter.subjectId = '';
            this.record_filter.topicId = '';
            this.selectSubject();
            this.selectTopic();
            this.fetchTopicReviewStatuses();
        },
        selectSubject(){
            TokenService.saveTopicReviewBoard("subjectId", this.record_filter.subjectId);
            this.record_filter.topicId = '';
            this.selectTopic();
            this.fetchTopicReviewStatuses();
        },
        selectTopic(){
            TokenService.saveTopicReviewBoard("topicId", this.record_filter.topicId);
            this.fetchTopicReviewStatuses();
        },
        selectReportTime(){
            TokenService.saveTopicReviewBoard("reportTime", this.record_filter.reportTime);
            this.fetchTopicReviewStatuses();
        },
        async fetchTopicReviewStatuses(page = 1) {
            this.show = true;
            const payload = {};
            payload.search = this.searchQuery;
            payload.assigneeId = this.record_filter.assigneeId;
            payload.gradeId = this.record_filter.gradeId;
            payload.subjectId = this.record_filter.subjectId;
            payload.topicId = this.record_filter.topicId;
            payload.reportTime = this.record_filter.reportTime;
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + `/topics/review/statuses?page=${page}`, payload, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.statuses = res.data.statuses;
                this.topicstodo = res.data.topicstodo;
                this.get_assignees = res.data.assignees;
                this.get_subjects = res.data.subjects;
                this.get_topics = res.data.topics;
                this.get_report_time = res.data.reportTime;
                this.get_report = res.data.getReport;
                this.show = false;
                this.getTopicsAccess = res.data.topicsAccess;
            } catch (error) { }
        },
        formatDate(dateObj) {
            const utcDate = new Date(dateObj);

            const gmtOffset = 5;
            const offsetInMilliseconds = gmtOffset * 60 * 60 * 1000;

            const gmtDate = new Date(utcDate.getTime() + offsetInMilliseconds);

            const day = String(gmtDate.getUTCDate()).padStart(2, '0');
            const month = String(gmtDate.getUTCMonth() + 1).padStart(2, '0');
            const year = gmtDate.getUTCFullYear();
            const hours = String(gmtDate.getUTCHours()).padStart(2, '0');
            const minutes = String(gmtDate.getUTCMinutes()).padStart(2, '0');
            const seconds = String(gmtDate.getUTCSeconds()).padStart(2, '0');

            return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} GMT+5`;

        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__open-indicator {
    fill: #9d9d9d !important;
}
</style>
